import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import image1 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/freedom-on-wheels.jpg'
import image2 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/after-chaos_2008.jpg'
import image3 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/inhale-exhale-explode_2010.jpg'
import image4 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/in-the-garden_2008.jpg'
import image5 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/rococco-shoes_2008.jpg'
import image6 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/memories-and-flowers-of-a-warrior-in-a-sandbox_2007.jpg'
import image7 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/secrets-and-flowers-of-a-warrior-in-a-sandbox_2008.jpg'
import image8 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/sitting-pretty_2008.jpg'
import image9 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/thoughts-and-flowers-of-a-warrior-in-a-sandbox_2007.jpg'
import image10 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/waiting-in-white_2008.jpg'
import image11 from '../../../assets/images/albums/ceramics-and-mixed-media/early-works/mis-caderas-mojadas-te-esperan_2012.jpg'


export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: image1,
    width: 960,
    height: 720,
    caption: 'Freedom on Wheels - Cluj Napoca Ceramic Biennale Collection'
  },
  {
    src: image2,
    width: 960,
    height: 773,
    caption: 'After Chaos - ceramic & mixed media - Private Collection'
  },
  {
    src: image3,
    width: 960,
    height: 720,
    caption: 'Inhale.Exhale.Explode - ceramic & mixed media - 14"x12" ea.'
  },
  {
    src: image4,
    width: 720,
    height: 960,
    caption: 'In the Garden - Private Collection'
  },
  {
    src: image5,
    width: 720,
    height: 960,
    caption: 'Rococco Shoes - Private Collection'
  },
  {
    src: image6,
    width: 720,
    height: 960,
    caption: 'Memories & Flowers of a Warrior in a Sandbox - ceramic & mixed media - 8"x5"'
  },
  {
    src: image7,
    width: 720,
    height: 960,
    caption: 'Secrets & Flowers of a Warrior in a Sandbox - ceramic & mixed media - Private Collection'
  },
  {
    src: image8,
    width: 720,
    height: 960,
    caption: 'Sitting Pretty - Private Collection'
  },
  {
    src: image9,
    width: 720,
    height: 960,
    caption: 'Thoughts & Flowers of a Warrior in a Sandbox - ceramic & mixed media - 15"x8"x7.5"'
  },
  {
    src: image10,
    width: 720,
    height: 960,
    caption: 'Waiting in White - Private Collection'
  },
  {
    src: image11,
    width: 720,
    height: 960,
    caption: 'Mis caderas mojadas te esperan - ceramic & mixed media - 41.5"x9" - N/A'
  },
]
